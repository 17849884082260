import { defineStore } from 'pinia';
import * as Project from '~/static/urls/project';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { PROJECT_METHODOLOGIES } from '@/utils/activity';

import { computed, ref } from 'vue';

const useProjectHelperStore = defineStore(
  'projectHelperStore',
  () => {
    const methodologies = ref([]);
    const projectMetaData = ref(null);
    const projectKeyRefs = ref({});
    const projectFormulaRefs = ref({});
    const projectFormulas = ref({});
    const projectItemRefs = ref({});
    const clearObj = useClearFetchStore();

    const setMethodologies = (methodologyList) => {
      methodologies.value = methodologyList;
    };
    const setProjectMetaData = (projectData) => {
      projectMetaData.value = projectData;
    };

    const clearProjectHelperData = () => {
      methodologies.value = [];
      projectMetaData.value = null;
      projectKeyRefs.value = {};
      projectFormulaRefs.value = {};
      projectFormulas.value = {};
      projectItemRefs.value = {};
    };
    const setProjectKeyRefs = (key, value, isReset = false) => {
      if (isReset) {
        projectKeyRefs.value = {};
      }
      if (key !== null) {
        const keyRef = projectKeyRefs.value;
        keyRef[key] = value;
        projectKeyRefs.value = keyRef;
      }
    };
    const setProjectFormulaRefs = (key, value, isReset = false) => {
      if (isReset) {
        projectFormulaRefs.value = {};
      }
      if (key !== null) {
        const keyRef = projectFormulaRefs.value;
        keyRef[key] = value && !/[a-zA-Z]+/.test(value) ? parseFloat(value) : getProjectKeyRefs[key];
        projectFormulaRefs.value = keyRef;
      }
    };
    const setProjectFormulas = (key, value, isReset = false) => {
      if (isReset) {
        projectFormulas.value = {};
      }
      if (key !== null) {
        const keyRef = projectFormulas.value;
        keyRef[key] = value;
        projectFormulas.value = keyRef;
      }
    };

    const setProjectItemRefs = (key, value, isReset = false) => {
      if (isReset) {
        projectItemRefs.value = {};
      }
      if (key !== null) {
        const keyRef = projectItemRefs.value;
        keyRef[key] = value;
        projectItemRefs.value = keyRef;
      }
    };

    const resetProjectRefs = () => {
      setProjectKeyRefs(null, null, true);
      setProjectFormulaRefs(null, null, true);
      setProjectItemRefs(null, null, true);
      setProjectFormulas(null, null, true);
    };
    const handleMethodologies = async () => {
      await clearObj.clearGet(Project.API_GET_METHODOLOGIES);
      if (clearObj.storeData && clearObj.storeData.data?.defaults) {
        methodologies.value = clearObj.storeData.data.defaults;
      } else {
        methodologies.value = [];
      }
      clearObj.setLastActivity(PROJECT_METHODOLOGIES);
    };

    const getProjectKeyRefs = computed(() => projectKeyRefs.value);
    const getProjectFormulaRefs = computed(() => projectFormulaRefs.value);
    const getProjectItemRefs = computed(() => projectItemRefs.value);
    const getProjectFormulas = computed(() => projectFormulas.value);

    const isPending = computed(() => clearObj.pending);

    const lastActivityLog = computed(() => clearObj.lastActivityLog);

    return {
      // state
      methodologies,
      projectMetaData,

      isPending,
      lastActivityLog,

      getProjectKeyRefs,
      getProjectFormulaRefs,
      getProjectItemRefs,
      getProjectFormulas,

      // actions
      handleMethodologies,
      setMethodologies,
      setProjectMetaData,
      setProjectKeyRefs,
      setProjectFormulaRefs,
      setProjectItemRefs,
      setProjectFormulas,
      resetProjectRefs,
      clearProjectHelperData
    };
  },
  {
    persist: true
  }
);

export default useProjectHelperStore;
