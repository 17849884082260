import { FORMULA_VALUES_DECIMAL_FIX } from '@/utils/constants.js';
import { all, create } from 'mathjs';

const config = {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'number',
  precision: 64,
  predictable: false,
  randomSeed: null
};
const mathLib = create(all, config);
mathLib.config({
  number: 'BigNumber'
});
export const compileFormInput = (str) => {
  if (typeof str === 'object') {
    str = Number(str);
  }
  return Number(str).toFixed(FORMULA_VALUES_DECIMAL_FIX);
};
export const checkFormulaContainsString = (formula) => {
  return /[a-zA-Z]+/.test(formula);
};
export const evaluateFormData = (str) => {
  let data = 0;

  if (!checkFormulaContainsString(str)) {
    data = mathLib.evaluate(str);
    // data = eval(str);
  }

  if (Number(data) === 0 || Number(data) === Infinity) {
    return '';
  }
  if (!isNaN(Number(data))) {
    return Number(data).toFixed(FORMULA_VALUES_DECIMAL_FIX);
  } else {
    return '';
  }
};

export const calculateInstanceTotal = (fieldInstance, instanceId, stepId) => {
  let total = 0;
  fieldInstance
    ?.find((instance) => instance.id === instanceId)
    ?.reporting_units?.filter((unit) => unit.stepId === stepId)
    ?.forEach((measurement) => {
      measurement?.formAttr?.forEach((item) => {
        if (item?.formType?.input_source?.slug === 'result' && item?.value !== '') {
          total = parseFloat(total) + parseFloat(item.value);
        }
      });
    });
  return total;
};
export const countInstanceReporting = (fieldInstance, instanceId, stepId) => {
  let totalCount = 0;
  fieldInstance
    ?.find((instance) => instance.id === instanceId)
    ?.reporting_units?.filter((unit) => unit.stepId === stepId)
    ?.forEach((measurement) => {
      measurement?.formAttr?.forEach((item) => {
        if (item?.formType?.input_source?.slug === 'result' && item?.value !== '') {
          totalCount += 1;
        }
      });
    });
  return totalCount;
};
export const calculateFieldTotal = (fieldId, fieldInstances) => {
  let fieldTotal = 0,
    touchedInput = 0;
  fieldInstances?.forEach((instance) => {
    instance?.reporting_units?.forEach((measurement) => {
      let itemStepId = 0;
      measurement?.formAttr?.forEach((formData) => {
        if (formData.value !== '' && Number(fieldId) === Number(formData.keyId)) {
          fieldTotal += parseFloat(formData.value);
          itemStepId = formData.step;
        }
      });

      if (measurement.stepId === itemStepId)
        touchedInput += measurement?.formAttr?.filter(
          (item) => item.formType.slug === 'result' && item.value !== ''
        ).length;
    });
  });

  if (!fieldTotal) {
    fieldTotal = 0;
  }
  if (touchedInput > 0) {
    return fieldTotal / touchedInput;
  }
  return fieldTotal;
};
export const replaceSumi = (formula, measurement, operatedMeasurement) => {
  if (formula && formula.includes('SUMi')) {
    let subFormula = formula;
    let SUMiBigFlag = false;
    let SUMiMiddleFlag = false;
    let SUMiSmallFlag = false;
    if (formula.includes('SUMi{') && formula.includes('}')) {
      subFormula = formula.split('SUMi{')[1].split('}');
      let subLength = subFormula.length;
      if (subLength > 1) {
        subFormula = subFormula[0];
        SUMiMiddleFlag = true;
      }
    }

    if (formula.includes('SUMi[') && formula.includes(']')) {
      subFormula = formula.split('SUMi[')[1].split(']')[0];
      SUMiBigFlag = true;
    }

    if (!SUMiBigFlag && !SUMiMiddleFlag && formula.includes('SUMi(')) {
      subFormula = formula.split('SUMi(')[1];
      let otherBrackets = subFormula.split(')').length;
      if (otherBrackets > 2) {
        subFormula = subFormula
          .split(')')
          .splice(otherBrackets - 1, 1)
          .join(')');
      } else {
        subFormula = subFormula.split(')')[0];
      }
      SUMiSmallFlag = true;
    }

    let subFormulaHolder = subFormula;
    let formulaSum = [];
    if (typeof subFormula === 'object') {
      subFormula = subFormula.join('');
    }
    if ((SUMiMiddleFlag || SUMiBigFlag || SUMiSmallFlag) && measurement && subFormula) {
      subFormula = subFormula.split('[').join('[ ').split(']').join(' ]').split('(').join('( ').split(')').join(' )');
      let yearTouchedFlag = false;
      let formulaIY = subFormula;
      measurement?.formAttr?.map((formData) => {
        let ref = formData.reference.replace(`_${formData.step}_${formData.instance}_${formData.measurement}`, '');
        if (formulaIY.includes(ref)) {
          formulaIY = formulaIY.split(ref).join(` ${formData.reference} `);
        }

        if (formData.formType.input_source.slug === 'user-input' && formData.value !== '') {
          yearTouchedFlag = true;
        }

        return formData;
      });
      if (yearTouchedFlag || measurement.fleetName.toString() === operatedMeasurement.toString()) {
        formulaSum.push(`( ${formulaIY} )`);
      }
    }
    let formulaInstanceSum = formulaSum.join(' + ');
    if (SUMiMiddleFlag && formulaInstanceSum !== '') {
      formula = formula.split(`SUMi{${subFormulaHolder}}`).join(formulaInstanceSum);
    }
    if (SUMiBigFlag && formulaInstanceSum !== '') {
      formula = formula.split(`SUMi[${subFormulaHolder}]`).join(formulaInstanceSum);
    }

    if (SUMiSmallFlag && formulaInstanceSum !== '') {
      formula = formula.split(`SUMi(${subFormulaHolder})`).join(formulaInstanceSum);
    }
  }
  return formula.replaceAll('[', '(').replaceAll(']', ')').replaceAll('{', '(').replaceAll('}', ')');
};

export const getItemValue = (instance, stepId, instanceKeyIndx, control_field_id, isAvg = true) => {
  let sumTotal = 0;
  let instanceReportingCount = 0;
  instance.reporting_units
    .filter((unit) => unit.stepId === stepId)
    .map((reportingUnit) => {
      reportingUnit.formAttr
        .filter((item) => Number(item.formType.id) === Number(control_field_id))
        .map((formData) => {
          if (formData.value !== '') {
            sumTotal += Number(formData.value);
            instanceReportingCount += 1;
          }
        });
    });
  let returnValue = null;
  if (isAvg && instanceReportingCount === 0) {
    instanceReportingCount = 1;
    returnValue = Number(sumTotal) / Number(instanceReportingCount);
  } else {
    returnValue = Number(sumTotal);
  }
  if (isNaN(returnValue)) {
    returnValue = 0;
  }
  return returnValue;
};
