import useProjectHelperStore from '@/stores/useProjectHelperStore';
import { storeToRefs } from 'pinia';

export default (slug = 'fleet') => {
  const projectHelperStore = useProjectHelperStore();
  const { projectMetaData } = storeToRefs(projectHelperStore);
  return (
    projectMetaData?.value?.projectAssetType?.methodology_defaults?.find((mdefault) => mdefault?.slug === slug)
      ?.label || ''
  );
};
