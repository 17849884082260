import { defineStore } from 'pinia';
import * as Project from '~/static/urls/project';
import useClearFetchStore from '@/stores/useClearFetchStore';
import {
  PROJECT_CREATION,
  PROJECT_CREATION_SAVE_INFO,
  PROJECT_DELETION,
  PROJECT_LIST,
  PROJECT_UPDATION
} from '@/utils/activity';
import useAuthStore from '@/stores/useAuthStore';
import useGeneralStore from '@/stores/useGeneralStore';
import { computed, ref, watch } from 'vue';
import { handleNumberString, navigateTo, useGetDefaultMethodologyBySlug, useNuxtApp } from '#imports';
import useProjectHelperStore from '@/stores/useProjectHelperStore';
import { handleFloatedNumberString, handleFloatNumber, projectFilterObj, projectSortObj } from '@/utils/config';
import {
  calculateFieldTotal,
  calculateInstanceTotal,
  checkFormulaContainsString,
  countInstanceReporting,
  evaluateFormData,
  getItemValue,
  replaceSumi
} from '@/utils/businessRule/vmProject';

const useProjectStore = defineStore(
  'projectStore',
  () => {
    const nuxtApp = useNuxtApp();
    const projectData = ref([]);
    const methodologies = ref([]);
    const projectInfoAttrs = ref([]);
    const project = ref(null);
    const selectedTabId = ref(1);
    const selectedInputTabId = ref('');
    const selectedStepId = ref(1);
    const selectedYear = ref('');
    const successMessage = ref(null);
    const clearObj = useClearFetchStore();
    const authObj = useAuthStore();
    const genObj = useGeneralStore();
    const projectHelperObj = useProjectHelperStore();
    const steppers = ref([]);
    const measurementsAttr = ref({});
    const verificationData = ref([]);
    const verifierCompanies = ref([]);
    const projectVerifiers = ref([]);
    const showFleetTab = ref(false);
    const summaryPageVisited = ref(false);
    const filesArray = ref([]);
    const projectFilter = ref(projectFilterObj());
    const projectSorting = ref(projectSortObj());

    const setProject = (projectValue) => {
      project.value = projectValue;
      return Promise.resolve(true);
    };
    const setProjectFilter = (filters) => {
      projectFilter.value = filters;
    };

    const setProjectSortType = (sortBy) => {
      projectSorting.value = sortBy;
    };

    const getProjectFilter = computed(() => projectFilter.value);

    const clearProjectData = () => {
      projectData.value = [];
      projectFilter.value = [];
      methodologies.value = [];
      projectInfoAttrs.value = [];
      project.value = null;
      selectedTabId.value = 1;
      selectedInputTabId.value = '';
      selectedStepId.value = 1;
      selectedYear.value = '';
      successMessage.value = '';
      steppers.value = [];
      measurementsAttr.value = [];
      verificationData.value = [];
      verifierCompanies.value = [];
      projectVerifiers.value = [];
      showFleetTab.value = false;
      summaryPageVisited.value = false;
      filesArray.value = [];
      return Promise.resolve();
    };
    const setFilesArray = (files) => {
      filesArray.value = files;
    };

    const getFilesArray = computed(() => filesArray.value);

    const setVerifierCompanies = (companies) => {
      verifierCompanies.value = companies;
    };

    const updateSuccess = (msg) => {
      successMessage.value = msg;
    };

    const setProjectInfoAttrs = (infoAttrs) => {
      projectInfoAttrs.value = infoAttrs;
    };
    const setMethodologies = (methodologyList) => {
      methodologies.value = methodologyList;
    };
    const setSummaryPageVisited = () => {
      summaryPageVisited.value = true;
    };
    const isSummaryPageVisited = computed(() => {
      return summaryPageVisited.value;
    });

    /**
     *
     * @param {*} tabId
     */
    const setActiveTab = (tabId) => {
      selectedTabId.value = tabId;
    };

    /**
     *
     * Get active tab/fleet id
     */
    const getActiveTabId = computed(() => {
      return selectedTabId.value;
    });

    const getMethodologyList = computed(() => methodologies.value);
    const setInputTabId = (id) => {
      selectedInputTabId.value = id;
    };

    const setSelectedStepId = (stepId) => {
      selectedStepId.value = stepId;
    };

    const handleList = async (userRequestData) => {
      const filterData = JSON.parse(JSON.stringify(userRequestData));

      if (authObj.isSuperAdmin) {
        if (genObj.realmId) {
          await clearObj.clearGet(
            `${Project.API_GET_URL_BY_REALM.replace('{realmId}', genObj.realmId)}`,
            false,
            filterData
          );
          if (clearObj.storeData) {
            projectData.value = clearObj.storeData.data;
          }
        } else {
          // do nothing
        }
      } else {
        await clearObj.clearGet(`${Project.API_GET_URL}`, false, filterData);
        if (clearObj.storeData) {
          projectData.value = clearObj.storeData.data;
        }
      }
      clearObj.setLastActivity(PROJECT_LIST);
    };

    const handleView = async (id) => {
      const { data } = await clearObj.clearGet(Project.API_VIEW_URL.replace('{project}', id), true);
      if (data && data?.value?.data) {
        setProject(data?.value?.data);
      }
    };

    const handleCreate = async (projectDataArg) => {
      const { data, error } = await clearObj.clearPost(Project.API_POST_URL, projectDataArg, true);
      if (error.value) {
        clearObj.showToastMessage(error.value?.data?.data);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
        clearObj.showToastMessage(data.value.message, true);
        clearObj.setLastActivity(PROJECT_CREATION);
      }
    };

    const handleProjectInfoSubmit = async (projectInfo) => {
      const { data, error } = await clearObj.clearPost(Project.API_SAVE_PROJECT_INFO, projectInfo, true);
      if (error.value) {
        clearObj.showToastMessage(error.value?.data?.data);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
        clearObj.setLastActivity(PROJECT_CREATION_SAVE_INFO);
      }
    };
    const handleProjectInfoUpdate = async (projectInfo) => {
      const { data, error } = await clearObj.clearPut(
        Project.API_UPDATE_PROJECT_INFO.replace('{projectId}', project.value.id),
        projectInfo,
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
      }
    };

    const handleAPIProject = async (projectDataArg) => {
      const { data, error } = await clearObj.clearPost(Project.API_POST_CREATE_API, projectDataArg, true);
      if (error.value) {
        clearObj.showToastMessage(error.value?.data?.data);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
        clearObj.setLastActivity(PROJECT_CREATION);
      }
    };

    const handleUpdate = async (id, projectDataArg) => {
      const { data, error } = await clearObj.clearPut(
        Project.API_UPDATE_URL.replace('{project}', id),
        projectDataArg,
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
        clearObj.setLastActivity(PROJECT_UPDATION);
      }
    };

    const handleProjectNameUpdate = async (projectInfo, params) => {
      const { data, error } = await clearObj.clearPut(
        Project.API_UPDATE_PROJECT_NAME.replace('{projectId}', projectInfo?.id),
        params,
        true
      );
      if (error?.value?.statusCode === 400) {
        clearObj.showToastMessage(error?.value?.data?.data);
        return false;
      }
      return new Promise((resolve, reject) => {
        if (error.value) {
          clearObj.showToastMessage(error.value?.message);
          reject(error.value);
        } else {
          setProject(data.value.data);
          updateSuccess(data.value.message);
          clearObj.setLastActivity(PROJECT_UPDATION);
          clearObj.showToastMessage(data?.value?.message, true);
          resolve();
        }
      });
    };

    const handleDelete = async (id) => {
      const { data, error } = await clearObj.clearDelete(Project.API_DELETE_URL.replace('{project}', id), true);
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        setProject(data.value.data);
        updateSuccess(data.value.message);
        clearObj.setLastActivity(PROJECT_DELETION);
      }
    };

    const handleClaimProject = async (id) => {
      const { error } = await clearObj.clearPut(
        Project.API_PUT_CLAIM_PROJECT.replace('{projectId}', id),
        {
          projectId: id
        },
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        return navigateTo('/projects');
      }
    };

    const handleAcceptCredit = async (id) => {
      const { error } = await clearObj.clearPut(
        Project.API_ACCEPT_CREDITS.replace('{projectId}', id),
        { projectId: id },
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        return navigateTo('/assets');
      }
    };

    const getMethodologies = async () => {
      const { data, error } = await clearObj.clearGet(`${Project.API_GET_METHODOLOGIES}?api=true`, true);
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else if(data.value){
        setMethodologies(data.value?.data);
      }else{
        //noop
      }
    };

    const getProjectInfoAttrs = async (methodologyId) => {
      const { data, error } = await clearObj.clearGet(
        Project.API_GET_DEFAULT_INFO_ATTRIBUTES.replace('{methodologyTypeId}', methodologyId),
        true
      );
      if (!error.value && data.value.data) {
        setProjectInfoAttrs(data.value.data);
      }
    };

    const getVerifierCompanies = async (methodologyId) => {
      const { data, error } = await clearObj.clearGet(
        Project.API_GET_VERIFIER_COMPANIES.replace('{methodologyId}', methodologyId),
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        setVerifierCompanies(data.value.data);
      }
    };

    const handleProjectVerifiers = async (projectId) => {
      const { data, error } = await clearObj.clearGet(
        Project.PROJECT_VERIFIERS.replace('{projectId}', projectId),
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        projectVerifiers.value = data.value.data;
      }
    };

    const getProjectVerifiers = computed(() => projectVerifiers.value);

    const getVerifierList = computed(() => verifierCompanies.value);

    const sendForVerification = async (id, projectDataArg) => {
      const { error } = await clearObj.clearPut(
        Project.API_POST_SEND_PROJECT_FOR_VERIFICATION.replace('{projectId}', id),
        projectDataArg,
        true
      );
      if (error.value) {
        clearObj.showToastMessage(error.value?.message);
      } else {
        project.value.status = 2;
        clearObj.setLastActivity(PROJECT_UPDATION);
      }
    };

    const getErrorObj = computed(() => clearObj.getError);

    const getError = computed(() => clearObj.error);

    const isProjectReadOnly = computed(
      () => !([1, 4].includes(project?.value?.status) && nuxtApp?.$ability?.can('create', 'Project'))
    );

    const resetState = () => {
      setProject(null);
      projectData.value = [];
    };

    const getListing = computed(() => {
      if (!projectData.value) {
        return [];
      }
      return projectData.value.list;
    });

    const getFrom = computed(() => {
      if (!projectData.value) {
        return 0;
      }
      return projectData.value.from;
    });

    const getTo = computed(() => {
      if (!projectData.value) {
        return 0;
      }
      return projectData.value.to;
    });

    const getCurrentPage = computed(() => {
      if (!projectData.value) {
        return 0;
      }
      return projectData.value.current_page;
    });

    const getLastPage = computed(() => {
      if (!projectData.value) {
        return 0;
      }
      return projectData.value.lastPage;
    });

    const getTotal = computed(() => {
      if (!projectData.value) {
        return 0;
      }
      return projectData.value.total;
    });
    const isPending = computed(() => clearObj.pending);

    const setErrorNull = () => {
      clearObj.setErrorNull();
    };

    const lastActivityLog = computed(() => clearObj.lastActivityLog);

    const getSelectedStepManufacturerUnits = computed(() => {
      return getActiveInstance?.value?.reporting_units?.filter((unit) => unit.stepId === selectedStepId.value);
    });

    /**
     * ACtive instance
     */
    const getActiveInstance = computed(() => {
      return steppers?.value?.instances?.find((inst) => inst.id === selectedTabId.value);
    });

    /**
     *
     * @param {create project methods}
     */
    const setSteppersData = (data) => {
      steppers.value = data;
    };

    /**
     *  showFleetTab
     */
    const dispalyFleet = (status) => {
      showFleetTab.value = status;
    };
    const shouldDisplayFleet = computed(() => showFleetTab.value);
    /**
     * set instance measurement form attributes
     */
    const setInstanceMeasurementAttr = (attr) => {
      measurementsAttr.value = attr;
    };

    const resetCreateProjectData = () => {
      selectedStepId.value = 1;
      selectedTabId.value = 1;
      selectedYear.value = '';
      summaryPageVisited.value = false;
      steppers.value = [];
    };
    const getSteppers = computed(() => {
      return steppers.value;
    });

    const getProject = computed(() => project.value);

    const getTotalStepsCount = computed(() => getProject?.value?.methodology?.process_flow?.steps?.length);

    const setMeasurementYear = (slug) => {
      selectedYear.value = slug;
    };
    const getSelectedYear = computed(() => {
      return selectedYear.value;
    });
    const getSelectedYearOnly = computed(() => {
      return getSelectedYear?.value?.replace(useGetDefaultMethodologyBySlug('year'), ' ');
    });
    const getSelectedFleet = computed(() => {
      return (
        steppers?.value?.instances?.find((instance) => instance.id === selectedTabId.value)?.name ||
        project?.value?.project_instances?.find((instance) => instance.id === selectedTabId.value)?.name
      );
    });
    const getSelectedInstance = computed(() => {
      return steppers?.value?.instances?.find((instance) => instance.id === selectedTabId.value);
    });
    const getSelectedStepName = computed(() => {
      return steppers?.value?.steps?.find((item) => item.id === selectedStepId.value)?.label.replace('Calculation', '');
    });

    const getActiveStep = computed(() => selectedStepId.value);

    const getSummarySteps = computed(() => {
      const newSteppers = steppers?.value?.steps?.filter((item) => [2, 3, 4]?.includes(item.id));
      return newSteppers;
    });

    /**
     *  get measurementsAttr
     */
    const getMeasurementsAttr = computed(() => {
      return measurementsAttr.value;
    });
    /**
     *  Get selected steps form
     */
    const getSelectedStepFormAttr = computed(() => {
      return getSelectedStepManufacturerUnits?.value?.find((unit) => unit.name === selectedYear.value)?.formAttr || [];
    });

    const getInstances = computed(() => {
      return steppers?.value?.instances;
    });
    const getProjectName = computed(() => {
      return steppers?.value?.projectInfo?.find((info) => info.slug === 'project-name')?.value;
    });
    const getUpdatedProjectName = computed(() => {
      const projectName = project?.value?.project_info?.find(
        (item) => item?.project_info_definition?.slug === 'project-name'
      )?.attr_value;
      if (projectName !== undefined) {
        return projectName;
      }
      return '';
    });
    const getBaselineEmissionRange = computed(() => {
      return steppers?.value?.projectInfo?.find((info) =>
        ['years-for-baseline-data', 'year-for-baseline-data'].includes(info.slug)
      )?.value;
    });
    const getYearForProjectRange = computed(() => {
      return steppers?.value?.projectInfo?.find((info) =>
        ['years-for-project-data', 'years-for-project-data', 'year-for-project-data'].includes(info.slug)
      )?.value;
    });

    const getMeasurementTotal = computed(() => {
      let total = 0;
      let selectedMeasurement = getSelectedInstance.value?.reporting_units?.find(
        (unit) => unit.name === getSelectedYear.value
      );

      if (selectedMeasurement) {
        total = selectedMeasurement?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
      }
      if (isNaN(parseFloat(total))) {
        total = 0;
      }
      return handleFloatNumber(total);
    });

    const getTouchedMeasurementCount = computed(() => {
      return getSelectedInstance.value?.reporting_units?.filter((unit) => {
        const elementValue = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
        return (
          !(elementValue === '' || elementValue === null) &&
          !isNaN(parseFloat(elementValue)) &&
          parseFloat(elementValue) > 0 &&
          unit.stepId == selectedStepId.value
        );
      }).length;
    });
    const getMeasurementTotalByData = (measurementUnit) => {
      let total = measurementUnit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
      if (total === undefined || total === '' || total === null) {
        return 0;
      }
      return handleNumberString(total);
    };

    const getInstanceTotal = computed(() => {
      let total = 0;
      let totalTouchedMeasurements = getTouchedMeasurementCount.value;
      getSelectedInstance.value?.reporting_units
        ?.filter((unit) => unit.stepId == selectedStepId.value)
        ?.map((unit) => {
          let unitTotal = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
          if (unitTotal) {
            let newUnitTotal = parseFloat(unitTotal);
            if (isNaN(newUnitTotal)) {
              newUnitTotal = 0;
            }
            total += newUnitTotal;
          }
        });
      if (isNaN(total)) {
        total = 0;
      }
      if (totalTouchedMeasurements > 0) {
        total = total / totalTouchedMeasurements;
      }
      return Number.parseFloat(total);
    });

    const getEmissionInstanceTotal = computed(() => {
      let total = 0;
      getSelectedInstance.value?.reporting_units
        ?.filter((unit) => unit.stepId == selectedStepId.value)
        ?.map((unit) => {
          let unitTotal = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
          if (unitTotal) {
            let newUnitTotal = parseFloat(unitTotal);

            if (isNaN(newUnitTotal)) {
              newUnitTotal = 0;
            }
            total += newUnitTotal;
          }
        });
      if (isNaN(total)) {
        total = 0;
      }
      return Number.parseFloat(total);
    });

    const getEmissionTotal = computed(() => {
      let total = 0;
      steppers?.value?.instances?.map((instance) => {
        instance?.reporting_units
          ?.filter((unit) => unit.stepId === getActiveStep.value)
          ?.map((unit) => {
            let resultValue = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
            if (resultValue) {
              if (getActiveStep.value === getTotalStepsCount?.value - 1) {
                total += parseFloat(handleNumberString(resultValue, true));
              } else {
                total += parseFloat(handleFloatedNumberString(resultValue));
              }
            }
            return unit;
          });
        return instance;
      });
      if (getActiveStep.value === getTotalStepsCount?.value - 1) {
        return handleNumberString(total, true);
      } else {
        return total;
      }
    });

    const getAvgBaseLineEmission = computed(() => {
      let avtTotal = 0;
      let touchedInstances = 0;
      // stepId
      steppers?.value?.instances?.map((instance) => {
        let total = 0;
        let touchedMeasurement = instance?.reporting_units?.filter(
          (unit) =>
            unit.stepId == getActiveStep.value &&
            unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value !== '' &&
            !isNaN(parseFloat(unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value))
        ).length;
        let touchedFlag = true;
        instance?.reporting_units
          ?.filter((unit) => unit.stepId === getActiveStep.value)
          ?.map((unit) => {
            let resultValue = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
            if (resultValue) {
              touchedFlag = touchedFlag && isNaN(parseFloat(resultValue));
              total += parseFloat(handleFloatedNumberString(resultValue));
            }
            return unit;
          });
        if (!touchedFlag) touchedInstances++;
        if (isNaN(total)) {
          total = 0;
        }
        if (touchedMeasurement > 0 && total) {
          avtTotal += total / touchedMeasurement;
        } else {
          avtTotal += total;
        }

        return instance;
      });
      if (touchedInstances > 1) {
        avtTotal = avtTotal / touchedInstances;
      }
      return Number.parseFloat(avtTotal);
    });

    const handleProjectDefaultData = (item) => {
      if (item?.formType?.control_type_attr?.calculation_base === 'instance-avg-field-assignment') {
        let fieldTotal = calculateFieldTotal(
          item.formType.control_type_attr.control_field,
          getSteppers?.value?.instances
        );
        item.value = Number(fieldTotal);
        if (isNaN(item.value)) {
          item.value = 0;
        }
        projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
        item.type = 'label';
      } else if (item?.formType?.control_type_attr?.slug === 'zero-assignment') {
        item.value = Number(0);
        projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
        item.type = 'label';
      } else if (item?.formType?.control_type_attr?.calculation_type === 'field-assigment') {
        if (item?.formType?.control_type_attr?.calculation_base === 'avg') {
          item.value = getItemValue(
            getActiveInstance?.value,
            item?.formType?.control_type_attr?.step_id,
            item.instance,
            item?.formType?.control_type_attr?.control_field,
            true
          );
          if (isNaN(item.value)) {
            item.value = 0;
          }
          if (item.value === 0) {
            item.value = '';
          } else {
            projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
          }
        } else {
          item.type = 'label';
          item.value = getItemValue(
            getActiveInstance?.value,
            item.formType?.control_type_attr?.step_id,
            item?.instance,
            item?.formType?.control_type_attr?.control_field
          );
          if (isNaN(item.value)) {
            item.value = 0;
          }
          if (item.value === 0) {
            item.value = '';
          } else {
            projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
          }
        }
        item.type = 'label';
      } else if (item?.formType?.control_type_attr?.calculation_type === 'default_value') {
        item.value = Number(item?.formType?.control_type_attr?.default_value);
        if (isNaN(item.value)) {
          item.value = 0;
        }
        projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
        item.type = 'label';
      }
      projectHelperObj.getProjectItemRefs[item.reference]?.map(async (elRef) => {
        await handleItemRefData(elRef, item.reference, item.value);
      });
    };
    const handleProjectData = (item) => {
      if (item?.formType?.control_type_attr?.calculation_base === 'instance-avg') {
        let reportingCount = countInstanceReporting(
          getSteppers?.value?.instances,
          item.instance,
          item.formType.control_type_attr.step_id
        );

        //getting baseline total
        let total = calculateInstanceTotal(
          getSteppers?.value?.instances,
          item.instance,
          item.formType.control_type_attr.step_id
        );
        if (Number(reportingCount) > 0) {
          item.value = Number(total) / Number(reportingCount);
        } else {
          item.value = Number(total);
        }
        if (isNaN(item.value)) {
          item.value = 0;
        }
        projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
        item.type = 'label';
      } else if (item?.formType?.control_type_attr?.calculation_base === 'instance-sum') {
        let total = calculateInstanceTotal(
          getSteppers?.value?.instances,
          item.instance,
          item.formType.control_type_attr.step_id
        );
        item.value = Number(total);
        if (isNaN(item.value)) {
          item.value = 0;
        }
        projectHelperObj.setProjectFormulaRefs(item.reference, item.value);
        item.type = 'label';
      }

      projectHelperObj.getProjectItemRefs[item.reference]?.map(async (elRef) => {
        await handleItemRefData(elRef, item.reference, item.value);
      });
    };

    const findRefItem = (itemRef) => {
      const elementRefExploded = itemRef.replace('instance_', '').split('_');
      const element = getInstances?.value
        ?.find((item) => Number(item.id) === Number(elementRefExploded[1]))
        ?.reporting_units?.find(
          (unit) =>
            Number(unit.stepId) === Number(elementRefExploded[0]) &&
            unit.fleetName.toString() === elementRefExploded[2].toString()
        )
        ?.formAttr?.find((attr) => attr.reference === itemRef);
      return element;
    };

    const handleItemRefData = async (elementRef, itemRef, itemVal) => {
      const element = await findRefItem(elementRef);
      projectHelperObj.setProjectFormulaRefs(itemRef, itemVal);
      if (element !== undefined) {
        if (element.formulaValues === undefined) {
          element.formulaValues = {};
        }
        element.formulaValues[itemRef] = itemVal;
        let formula = element?.updatedFormula;
        if (formula) {
          for (let itemKey in element?.formulaValues) {
            if (element?.formulaValues[itemKey] === '') {
              element.formulaValues[itemKey] = 0;
            } else {
              let elementValue = parseFloat(element.formulaValues[itemKey]);
              if (isNaN(elementValue)) {
                elementValue = 0;
              }
              element.formulaValues[itemKey] = elementValue;
            }
            formula = formula.replaceAll(itemKey, element?.formulaValues[itemKey]);
          }
          if (!checkFormulaContainsString(formula)) {
            let formulaValue = evaluateFormData(formula);
            if (isNaN(formulaValue)) {
              formulaValue = '';
            }
            element.value = formulaValue;

            projectHelperObj.setProjectFormulaRefs(element.reference, element.value);

            projectHelperObj.getProjectItemRefs[element.reference]?.map(async (elRef) => {
              await handleItemRefData(elRef, element.reference, element.value);
            });
          }
        }
      }
    };
    const handleRefrencesOnLoad = () => {
      getSteppers?.value?.instances?.map((instance) => {
        instance?.reporting_units?.map((unit) => {
          unit?.formAttr?.map(async (formData) => {
            projectHelperObj.setProjectKeyRefs(formData.reference, formData.formRef);
            projectHelperObj.setProjectFormulaRefs(formData.reference, 0);
            if (formData.value !== undefined || formData.value !== '') {
              projectHelperObj.setProjectFormulaRefs(formData.reference, formData.value);
            }
            if (formData.formType.formula) {
              let formula = formData.updatedFormulaRef;
              for (let prop in projectHelperObj.getProjectKeyRefs) {
                if (
                  prop.includes(`instance_${formData.step}_${formData.instance}_${formData.measurement}`) &&
                  !formula.includes(prop)
                ) {
                  formula = formula.replaceAll(projectHelperObj.getProjectKeyRefs[prop], prop);
                }
              }
              formData.formulaValues = {};
              formData.updatedFormula = replaceSumi(formula, unit, formData.measurement);
              projectHelperObj.setProjectFormulas(formData.reference, formData.updatedFormula);
            } else {
              handleProjectDefaultData(formData);
            }
            await handleProjectData(formData);
            return formData;
          });
          return unit;
        });
        return instance;
      });

      // find formula references
      for (let keyRef in projectHelperObj?.getProjectKeyRefs) {
        let projectItemKeyRefs = [];
        for (let formulasRef in projectHelperObj.getProjectFormulas) {
          if (projectHelperObj.getProjectFormulas[formulasRef].includes(keyRef)) {
            projectItemKeyRefs.push(formulasRef);
          }
        }
        projectHelperObj.setProjectItemRefs(keyRef, projectItemKeyRefs);
      }

      for (let proRef in projectHelperObj?.getProjectFormulaRefs) {
        if (projectHelperObj?.getProjectFormulaRefs[proRef] !== undefined) {
          projectHelperObj?.getProjectItemRefs[proRef]?.map(async (itemRef) => {
            await handleItemRefData(itemRef, proRef, projectHelperObj?.getProjectFormulaRefs[proRef]);
          });
        }
      }
    };

    const calculateEmissionTotal = (step) => {
      let total = 0;
      steppers?.value?.instances?.map((instance) => {
        instance?.reporting_units
          ?.filter((unit) => unit.stepId === step)
          ?.map((unit) => {
            let resultValue = unit?.formAttr?.find((item) => item?.formType?.input_source?.slug === 'result')?.value;
            if (resultValue) {
              if (getActiveStep.value === getTotalStepsCount?.value - 1) {
                total += parseFloat(handleNumberString(resultValue, true));
              } else {
                total += parseFloat(handleFloatedNumberString(resultValue));
              }
            }
            return unit;
          });
        return instance;
      });
      if (getActiveStep.value === getTotalStepsCount?.value - 1) {
        return handleNumberString(total, true);
      } else {
        return total;
      }
    };
    const getProjectSortBy = computed(() => projectSorting?.value);

    watch(getSelectedYear, (newValue, oldValue) => {
      if (newValue && newValue !== oldValue) {
        getSelectedStepFormAttr?.value?.map(async (formData) => {
          await handleProjectDefaultData(formData);
        });
      }
    });
    watch(getActiveStep, (newValue, oldValue) => {
      if (newValue && oldValue !== newValue) {
        getSelectedStepFormAttr?.value?.map(async (formData) => {
          await handleProjectDefaultData(formData);
        });
      }
    });

    return {
      // state
      project,
      projectData,
      successMessage,
      methodologies,
      projectInfoAttrs,
      selectedTabId,
      selectedInputTabId,
      selectedStepId,
      selectedYear,
      steppers,
      getSelectedStepManufacturerUnits,
      verificationData,
      verifierCompanies,
      projectFilter,
      projectSorting,

      // actions
      setProjectSortType,
      setProjectFilter,
      // actions
      handleList,
      handleView,
      handleCreate,
      handleUpdate,
      handleDelete,
      handleAPIProject,
      getMethodologies,
      getProjectInfoAttrs,
      resetState,
      updateSuccess,
      setProject,
      setErrorNull,
      setProjectInfoAttrs,
      setMethodologies,
      setActiveTab,
      setInputTabId,
      setSelectedStepId,
      setSteppersData,
      resetCreateProjectData,
      setMeasurementYear,
      setInstanceMeasurementAttr,
      handleProjectInfoSubmit,
      handleProjectInfoUpdate,
      handleProjectNameUpdate,
      getMeasurementTotalByData,
      // getProjectVerification,
      handleItemRefData,
      handleRefrencesOnLoad,
      setVerifierCompanies,
      getVerifierCompanies,
      sendForVerification,
      handleClaimProject,
      handleAcceptCredit,
      dispalyFleet,
      handleProjectVerifiers,
      setSummaryPageVisited,
      setFilesArray,
      calculateEmissionTotal,
      clearProjectData,
      // getters
      getProjectFilter,
      getProjectSortBy,
      getProjectVerifiers,
      getVerifierList,
      getListing,
      getFrom,
      getTo,
      getCurrentPage,
      getLastPage,
      getTotal,
      getErrorObj,
      getError,
      lastActivityLog,
      isPending,
      getProject,
      getSteppers,
      getSelectedYear,
      getSelectedYearOnly,
      getSelectedFleet,
      getSelectedInstance,
      getSelectedStepName,
      getMeasurementsAttr,
      getSelectedStepFormAttr,
      getInstances,
      getProjectName,
      getUpdatedProjectName,
      getBaselineEmissionRange,
      getYearForProjectRange,
      getMeasurementTotal,
      getInstanceTotal,
      getEmissionInstanceTotal,
      getTouchedMeasurementCount,
      getAvgBaseLineEmission,
      getEmissionTotal,
      getSummarySteps,
      getActiveStep,
      getActiveTabId,
      isProjectReadOnly,
      shouldDisplayFleet,
      isSummaryPageVisited,
      getFilesArray,
      getMethodologyList,
    };
  },
  {
    persist: false
  }
);

export default useProjectStore;
